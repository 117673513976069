import { cn } from '~/utils/css/css';

import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const bars = Array(12).fill(0);
const loaderVariants = cva(
  'absolute -left-[10%] -top-[3.9%] h-[8%] w-[24%] animate-spinner-loading-bar',
  {
    variants: {
      variant: {
        default: 'bg-primary-foreground',
        destructive: 'bg-destructive-foreground',
        'destructive-light': 'bg-secondary-foreground',
        text: 'bg-primary',
        secondary: 'bg-secondary-foreground',
        ghost: '',
        link: '',
        outline: 'bg-secondary-foreground',
        muted: 'bg-muted-foreground',
        background: 'bg-muted-foreground',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

interface LoaderProps extends VariantProps<typeof loaderVariants> {
  size?: number;
  className?: string;
}

export const Loader = ({ size = 20, variant, className }: LoaderProps) => {
  return (
    <div style={{ height: `${size}px`, width: `${size}px` }}>
      <div
        className={'relative left-1/2 top-1/2'}
        style={{ height: `${size}px`, width: `${size}px` }}>
        {bars.map((_, i) => (
          <div
            key={i}
            className={cn(className, loaderVariants({ variant }))}
            style={{
              transform: `rotate(${(i + 1) * 30}deg) translate(146%)`,
              animationDelay: `-${(bars.length - 1) * i * 0.1}s`,
            }}></div>
        ))}
      </div>
    </div>
  );
};
